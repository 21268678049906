@import "../../styles/config/animations";
@import "../../styles/config/mixins";

.portfolio {
  width: 100%;
  padding: 3% 10%;
  padding-bottom: calc(3% + 6rem);
  background-color: #fff;
  position: relative;
  z-index: 10;
  overflow: hidden;

  @include respond(tab-port) {
    padding-left: 5%;
    padding-right: 5%;
  }

  &__title {
    width: 100%;
    font-size: 3rem;
    font-family: "Merriweather Sans", sans-serif;
    font-weight: 700;
    margin-bottom: 5rem;
    text-align: center;
    text-transform: uppercase;
    opacity: 1;
    position: relative;
    opacity: 0;

    &::after {
      content: "";
      position: absolute;
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5rem;
      height: 0.4rem;
      background-color: currentColor;
      opacity: 0;
    }

    &Animate {
      animation: moveInRight 1s ease-out;
      opacity: 1;

      &::after {
        animation: moveInRightAfter 2s ease-out;
        opacity: 1;
      }
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    & > * {
      width: 100%;
      border: 1px solid #000;
    }

    @include respond(tab-port) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
