@import "../../styles/config/animations";

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #eee;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.8);

  &__text {
    color: #fff;
    text-align: center;
    cursor: default;
    opacity: 0;
    z-index: 10;

    &Animate {
      animation: fadeIn 3s ease-out;
      animation-fill-mode: backwards;
      opacity: 1;
    }
  }

  &__title {
    color: rgb(241, 239, 64);
    font-size: 4rem;
    font-weight: 700;
    font-family: "Roboto Slab", serif;
  }

  &__description {
    font-size: 3rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
  }
}
