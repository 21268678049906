@import "../../../styles/config/animations";

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-family: "Merriweather", serif;
  color: #4e5df2;
  padding: 2rem 2rem;

  &__icon {
    width: 50%;
    height: 50%;
  }

  &__title {
    font-size: 2rem;
    color: #000;
    font-family: "Merriweather Sans", sans-serif;
  }

  &__text {
    font-size: 1.5rem;
    color: #000;
  }

  &Animate {
    animation: moveInLeft 1s ease-out;
    animation-delay: 0.25s;
    animation-fill-mode: backwards;
  }
}
