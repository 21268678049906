@import "mixins";

$color-body: #999;
// $color-highlight-background: #000;
// $color-highlight-text: #fff;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; //1rem = 10px
  scroll-behavior: smooth;

  @include respond(tab-land) {
    font-size: 56.25%; //1rem = 9px
  }
  @include respond(tab-port) {
    font-size: 50%; //1rem = 8px
  }
  // @include respond(phone) {
  //   font-size: 30%; //1rem = 4.8px
  // }
  @include respond(big-desktop) {
    font-size: 75%; //1rem = 12px
  }
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.7;
  color: #000;

  //padding: 1.8rem; /* border around entire body */

  //remove border after it turns to tablet
  // @include respond(tab-port) {
  //   padding: 0; /* border around entire body */
  // }
}

//when highlighting text, change color
/*
::selection {
  color: color-highlight-text;
  background-color: color-highlight-background;
}
*/
