@import "../../styles/config/animations";
@import "../../styles/config/mixins";

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3% 10%;
  background-color: #eee;
  color: #000;
  font-weight: 400;
  position: relative;
  font-family: "Merriweather", serif;
  z-index: 2;

  @include respond(tab-port) {
    padding: 3% 5%; //1rem = 8px
  }

  &__title {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    font-family: "Merriweather Sans", sans-serif;
    text-transform: uppercase;
    position: relative;
    opacity: 0;

    &::after {
      content: "";
      position: absolute;
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5rem;
      height: 0.4rem;
      background-color: currentColor;
      opacity: 0;
    }

    &Animate {
      animation: moveInLeft 1s ease-out;
      animation-fill-mode: backwards;
      opacity: 1;

      &::after {
        animation: moveInLeftAfter 2s ease-out;
        animation-fill-mode: backwards;
        opacity: 1;
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;

    & > * {
      width: 100%;
    }
    @include respond(tab-port) {
      flex-direction: column; //1rem = 8px
    }
  }

  &__text {
    padding: 2%;
    font-size: 1.5rem;
    opacity: 0;

    &Animate {
      animation: moveInBottom 1s ease-out;
      animation-fill-mode: backwards;
      opacity: 1;
    }
  }

  &__link,
  &__link:link,
  &__link:active {
    text-decoration: none;
    display: inline-block;
    color: #4e5df2;
    font-weight: 900;
    position: relative;
    z-index: 3;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      height: 0%;
      width: 100%;
      background-color: #000;
      transition: all 0.5s;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 50%;
      left: 0;
      height: 0%;
      width: 100%;
      background-color: #000;
      transition: all 0.5s;
      z-index: -1;
    }

    &:hover {
      color: rgb(241, 239, 64);

      &:before,
      &:after {
        height: 50%;
      }
    }
  }

  &__description {
    padding-bottom: 2rem;
  }

  &__visual {
    opacity: 0;
    &Animate {
      animation: moveInBottom 1s ease-out;
      animation-fill-mode: backwards;
      opacity: 1;
    }
  }
}
