@import "../../styles/config/animations";
@import "../../styles/config/mixins";

.skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3% 0%;
  text-transform: uppercase;

  &__title {
    width: 100%;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    opacity: 1;
    font-family: "Merriweather Sans", sans-serif;

    &Animate {
      animation: moveInLeft 1s ease-out;
    }
  }

  &__container {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
    margin: 0 3rem;
    font-family: "Merriweather Sans", sans-serif;
  }

  &__bar {
    height: 3rem;
    width: 100%;
    display: flex;

    border-radius: 2px;
    overflow: hidden;
    color: #fff;

    &Name {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: 900;
      flex: 0 0 11rem; //whatever fixed with based on names
      background-color: #4e5df2;
    }

    &Body {
      position: relative;
      height: 100%;
      width: 100%;
      background-color: #888;
    }

    &Fill {
      height: 100%;
      font-weight: 900;
      background-color: #3d47a6;
      text-align: center;
      width: 0%;
      transition: all 2s;
      transition-delay: 0.25s;
    }

    &Rating {
      height: 100%;
      font-size: 1.5rem;
    }
  }
}
