.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 5rem;
  background-color: #000; //rgb(120, 120, 120);
  z-index: 1000;
  transition: all 0.5s;

  &__container {
    width: 100%;
    background-color: #000; //rgb(120, 120, 120);
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: flex;
    justify-content: center;
    transition: all 0.75s;

    &Sticky {
      position: fixed;
      top: -5rem;
      transition: all 1s;
      transform: translateY(100%);
    }
  }

  &__link,
  &__link:visited {
    position: relative;
    text-decoration: none;
    font-size: 1.7rem;
    text-transform: uppercase;
    padding: 1rem 0;
    margin: 0 2rem;
    color: #ddd;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      bottom: 0%;
      left: 0%;
      width: 100%;
      height: 0;
      background-color: rgb(241, 239, 64);
      transition: all 0.25s;
    }

    &:hover,
    &:focus {
      color: rgb(241, 239, 64);

      &::after {
        height: 0.3rem;
      }
    }
  }

  &__linkSection {
    color: rgb(241, 239, 64) !important;

    &::after {
      content: "";
      position: absolute;
      bottom: 0%;
      left: 0%;
      width: 100%;
      height: 0.3rem;
      background-color: rgb(241, 239, 64) !important;
      transition: all 0.25s;
    }
  }
}
