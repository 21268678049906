@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  80% {
    transform: translateX(2.5%);
  }
  100% {
    opacity: 1;
    transform: translate(0%);
  }
}

@keyframes moveInLeftAfter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateX(-500%);
  }
  80% {
    transform: translate(-25%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  80% {
    transform: translateX(-2.5%);
  }
  100% {
    opacity: 1;
    transform: translate(0%);
  }
}

@keyframes moveInRightAfter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateX(500%);
  }
  80% {
    transform: translate(-75%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popUp {
  0% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
