@import "../../styles/config/animations";

.project {
  position: relative;
  opacity: 0;
  background-color: #000;
  overflow: hidden;
  width: 100%;
  height: 18rem;

  &__image {
    object-fit: fill;
    object-position: center top;
    width: 100%;
    height: 18rem;
    transition: all 1s;
  }

  &:hover &__image {
    opacity: 0.15;
  }

  &__hidden {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    opacity: 0;
    transition: all 1s;
    z-index: 5;
  }

  &:hover &__hidden {
    display: flex;
    opacity: 1;
  }

  &__description {
    text-align: center;
    font-family: "Merriweather Sans", sans-serif;
  }

  &__title {
    font-size: 2.25rem;
    color: #fff;
  }

  &__about {
    font-size: 1.5rem;
    color: #4e5df2;
  }

  &__link,
  &__link:visited,
  &__link:link {
    text-decoration: none;
    border: 1px solid #f1ef40;
    color: #fff;
    font-size: 2rem;
    padding: 0.5rem 1.5rem;
  }

  &Animate {
    animation: moveInBottom 1s ease-out;
    animation-fill-mode: backwards;
    opacity: 1;
  }
}
