@import "../../../styles/config/mixins";

.features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: start;
  padding: 3% 0;

  @include respond(tab-port) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: repeat(1, 1fr);
  }

  & > * {
    width: 100%;
  }

  &__icon {
    width: 50%;
    height: 50%;

    @include respond(tab-port) {
      width: 25%;
    }
  }
}
